import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom"; // Import Link
import axios from "axios";
import "./SignIn.css";
import googleLogo from "../../assets/google-logo.png";
import facebookLogo from "../../assets/facebook-logo.png";
import linkedinLogo from "../../assets/linkedin-logo.png";

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Handle LinkedIn login
  const handleLinkedInLogin = () => {
    window.location.href = "https://monolithai.me/api/auth/linkedin-login";
  };

  // Handle email-password login
  const handleEmailLogin = async () => {
    if (!email || !password) {
      setErrorMessage("Please enter both email and password.");
      return;
    }
    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    try {
      const response = await axios.post("https://monolithai.me/api/auth/login", {
        email,
        password,
      });

      if (response.data && response.data.isValidUser) {
        navigate("/home");
      } else {
        setErrorMessage(response.data?.message || "Invalid credentials or subscription not active.");
      }
    } catch (error) {
      console.error("Error during login: ", error);
      setErrorMessage("An error occurred during login. Please try again.");
    }
  };

  // Validate email format
  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div className="sign-in-page">
      <div className="sign-in-container">
        <div className="sign-in-header">
          <h1>Welcome to Monolith AI</h1>
          <p>Please sign in to continue</p>
        </div>

        <div className="social-login-buttons">
          <img
            src={linkedinLogo}
            alt="LinkedIn Logo"
            className="social-logo social-button"
            onClick={handleLinkedInLogin}
          />
          <img
            src={googleLogo}
            alt="Google Logo"
            className="social-logo social-button"
            onClick={() => {
              window.location.href = "https://monolithai.me/api/auth/google-login";
            }}
          />
          <img
            src={facebookLogo}
            alt="Facebook Logo"
            className="social-logo social-button"
            onClick={() => {
              window.location.href = "https://monolithai.me/api/auth/facebook-login";
            }}
          />
        </div>

        <div className="divider">
          <hr />
          <span>or</span>
          <hr />
        </div>

        {/* Email login form */}
        <div className="email-login">
          <input
            type="email"
            className="input-field stylish-input"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            className="input-field stylish-input"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="email-login-button stylish-button" onClick={handleEmailLogin}>
            Login
          </button>
        </div>

        {/* Display error message if login fails */}
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        {/* Continue as guest button */}
        <button
          className="guest-button"
          onClick={() => {
            window.location.href = "https://monolithai.me/lander";
          }}
        >
          Continue as Guest
        </button>

        {/* New Register Link */}
        <p className="register-text">
          Don't have an account?{" "}
          <Link to="/register" className="register-link">
            Register here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
