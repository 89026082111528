import * as React from 'react';
import styles from './HomePage.module.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/monolith-white-transparent.png'; // Path to the logo image

const HomePage: React.FC = () => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.navBar}>
          <img src={logo} alt="Monolith AI Logo" className={styles.logo} />
          <div className={styles.navLinks}>
            <Link to="/contact" className={styles.link}>Contact</Link>
            <Link to="/signin" className={styles.button}>Login/Register</Link>
          </div>
        </div>
      </header>

      <main className={styles.mainContent}>
        <div className={styles.heroSection}>
          <h1 className={styles.mainHeading}>Your Data, Your Questions, Instant Answers</h1>
          <p className={styles.subText}>Discover how Monolith AI's Chat Bot can help you interact with your product documentation like never before.</p>
          <Link to="/demo" className={styles.demoButton}>Try the Demo</Link>
        </div>
      </main>

      <section className={styles.featuresSection}>
        <div className={styles.featureCard}>
          <h2 className={styles.featureHeading}>Understand Your Data</h2>
          <p>Utilize the power of AI to ask questions directly from your documents, getting precise and contextual responses.</p>
        </div>
        <div className={styles.featureCard}>
          <h2 className={styles.featureHeading}>Tailored to Your Needs</h2>
          <p>Our Chat Bot adapts to the specific requirements of your product documentation, making sure you get the answers you need.</p>
        </div>
        <div className={styles.featureCard}>
          <h2 className={styles.featureHeading}>Boost Efficiency</h2>
          <p>Reduce the time spent searching through documents. Let our Chat Bot do the heavy lifting for you.</p>
        </div>
      </section>

      <footer className={styles.footer}>
        <p>&copy; 2024 Monolith AI. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default HomePage;
